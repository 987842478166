
import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import { get_ApiManager, post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import Multiselect from "multiselect-react-dropdown";
import smallSpinner from '../../assets/small.gif';
import swal from 'sweetalert';

class ManageBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            user:'',
            allUsers: [],
            allBranches: [],
            selectedBranches: [],
            userError: '',
            branchesError: '',
            userName: '',
        }
    }

    componentDidMount() {
        this.getUsers();
        this.getBranches();
    }

    async getUsers() {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            this.setState({allUsers: res.data.data, loading: false});
        },(err) => {
            console.log(err);
        });
    }
    async getBranches() {
        let url = 'getBranches'; 
        get_ApiManager('', url,(res) => {
            console.log('branches',res);
            const modifiedData = res.data['data'];

            // Adding the new object to the array
            modifiedData.push({ name: "Others", id: 0 });
        
            this.setState({ allBranches: modifiedData });
        },(err) => {
            console.log(err);
        });
    }
    assignBranch = () => {
        this.setState({userError:'', branchesError:''})
        if(!this.state.user){
            this.setState({userError: 'Please select user!'})
        } else if(!this.state.selectedBranches[0]){
            this.setState({branchesError: 'Please select atleast one branch!'})
        } else{
            let url = 'assignBranches';
            const data = {
                user: this.state.user,
                branches : this.state.selectedBranches,
            }
            console.log('data', data);
            post_ApiManager(data, url, (res) => {
                console.log('branch-assigned',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else { 
                this.setState({user:'', userName:'', selectedBranches:[]});
                swal("Success!", " Branch/s has been assigned successfully!", "success");
                this.getUsers();
                }  
            },(err) => {
                console.log(err);
            });  
        }
    }

    handleBranchInputChanges = (index) => {
        const newArray = [...this.state.selectedBranches];
        newArray.splice(index, 1);
        this.setState({selectedBranches: newArray});
    };

    handleCheckboxChange = (userId, branchId) => {
        // this.setState({loading:true})
        let url = 'checkBranch';
        const data = {
            user_id: userId,
            branch_id : branchId,
            from:'manageBranch'
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('checked',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else { 
                this.getUsers();
            }  
        },(err) => {
            console.log(err);
        }); 
        
      };
      
      
      

    render(){
        const role = JSON.parse(localStorage.getItem('user_role'));
        let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
        return(
            <>
            {role === 2 &&
            <>
            <Title title="Manage Branches" />
            <div className="row w-100 pl-3 pr-3">
            {/* Assign Bar */}
            <div className='row p-3 bg-white border mb-2'>
            {/* All Users */}
            <div className='col-lg-3 mb-2'>
            <select value={this.state.user} onChange={(e) => this.setState({user: e.target.value, userName: e.target.options[e.target.selectedIndex].text})}
            className="form-select s-dropdown shadow-sm pt-1 w-lg-90 mt-1 form-control">
            <option selected>Select User</option>
            {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
                <option key={value.id} value={value.id}>{value.name}</option>
            )}
            </select>
            <div className='error'>{this.state.userError}</div>
            </div>
            {/* All Bracnhes */}
            <div className='col-lg-3 mb-2'>
            <Multiselect
            options={this.state.allBranches.slice().sort((a, b) => a.name.localeCompare(b.name))}
            className="form-select s-dropdown py-1 shadow-sm mt-1 chip-hide w-lg-90"
            placeholder={'Select Branch'}
            displayValue={"name"}
            onSelect={selected => this.setState({selectedBranches: selected})}
            showCheckbox={true}
            disablePreSelectedValues={true}
            hidePlaceholder={false}
            selectedValues={this.state.selectedBranches}
            />
            <div className='error'>{this.state.branchesError}</div>
            </div>
            {/* Assign Button */}
            <div className='col-lg-6 mb-2'>
            <button className="btn btn-info float-right w-100 text-left col-lg-4" 
            onClick={(e) => this.assignBranch(e)}>
            <i className="fa fa-tasks pr-2" aria-hidden="true"></i>Assign Branch</button>
            </div>

            <div className="row selectedValues mt-3">
             {/* Input1 searched value */}
            {this.state.user &&
            <div className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({user: ''})}>
                </button><div className="list-tag">{this.state.userName + ' ->'}</div>
            </div>}

              {/* search branches */}
            {this.state.selectedBranches &&
                [].concat(this.state.selectedBranches).sort((a, b) => a.name > b.name ? 1 : -1).map((item, index) => (
                <div key={index} className="tag-bg w-auto">
                    <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.handleBranchInputChanges(index)}>
                    </button><div className="list-tag">{item.name}</div>
                </div>
            ))}
            </div>
            </div>

            <div className='mb-5 px-2'>
            <table className="table table-sm">
            <thead>
            <tr>
            <th>#</th>
            <th>User Name</th>
            <th>Branch</th>
            </tr>
            </thead>
            <tbody>
            {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => {
            const branchIds = value.branch.split(',').map(id => parseInt(id.trim(), 10)); // Split and convert to integers
            const showBranchOnly = value.show_branch_only.split(',').map(id => parseInt(id.trim(), 10));
            const branchNames = branchIds.map(id => {
                const branch = this.state.allBranches.find(branch => branch.id === id);
                return branch ? branch.name : 'Unknown Branch';
            });
            return(
            <tr key={value.id}>
            <td className='w-15'>{++index}</td>
            <td className="name-text w-50">{value.name}</td>
            <td className=''>
            {branchNames.map((branchName, i) => (
            <div key={i}>
            {this.state.loading ?
            <img src={smallSpinner} width="15" className="pt-2" alt="loading" />
            :
            <input type="checkbox" id={`checkbox_${i}`} 
            checked={showBranchOnly.includes(branchIds[i])}
            onChange={() => this.handleCheckboxChange(value.id, branchIds[i])} />}
            <label htmlFor={`checkbox_${i}`} className='ml-1'> {branchName}</label>
            <br />
            </div> ))}
            </td>
            </tr>)})}
            </tbody>
            </table>
            </div>

            </div>
            </>}
            </>
        )
    }
}
export default ManageBranch;