
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { get_ApiManager, post_ApiManager} from "../ApiManager/ApiManager"
import swal from 'sweetalert';

class SendInboxEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appidError:null, messageError:null, agentError:null,
            appid:'',
            allApps:[],
            type:'',
            message:'',
            agentid:'',
            user: JSON.parse(localStorage.getItem('agent')) || {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
         this.props.parentCallback(this.props.show, false); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    } 
    handleSubmit() {
        this.setState({ appidError:null, messageError:null, agentError: null})
        if(!this.state.message){
            this.setState({messageError: 'Please enter message!'})
            return false;
        } else{
            let url = `p-sendEmailtoPartner`;
            const data = {
                type: 'edu',
                appid : this.props.appid,
                message:  this.state.message,
                agentid: this.props.agentid,
                client: this.props.client,
                coursename: this.props.course,
                provider: this.props.provider,
            }
            console.log(data);
            post_ApiManager(data, url, (res) => {
                console.log('email-sent',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success"); 
                    this.setState({appid:'', type:'',message:'',})
                    this.props.parentCallback('edit');
                   }  
            },(err) => {
                console.log(err);
            }); 
        }
    }
    
  render(){ 
      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}><div className="text-center col-11 font-f-sans white"></div></Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">

                <div className="form-group col-12" >
                <label className="profile-label ">Partner</label>
                <input  type="text" className='form-control acc-form' value={this.props.partner} disabled/>
                </div>

                <div className="form-group col-12" >
                <label className="profile-label">Message</label>
                <textarea className="textarea form-control acc-form" placeholder="Please Enter" 
                name="message" onChange={this.handleChange} rows="8" cols="60"></textarea>
                 {this.state.messageError && <><small className="error">{this.state.messageError}</small></>}
                </div>

                </div>

                </form>
                </Modal.Body>  
                <Modal.Footer>  
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
                {this.state.message ? 
                <Button className="btn btn-success" onClick={this.handleSubmit}>Send Email</Button>  :''}
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default SendInboxEmail