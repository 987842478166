

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import ImageUploadPreviewComponent from '../ImageUploadPreviewComponent';

class SendEmailModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input1:'',
            input2:'',
            input3:'',
            input4:'',
            input5:'',
            file: [],
            isEmailValid: true,
            showToPortal: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleCredentialSubmit = this.handleCredentialSubmit.bind(this);
    }

    handleModal(){
        this.props.parentCallback(this.props.show, false); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEmailChange(event) {
        const { value } = event.target;
    
        // Directly using the HTML5 validity check for the email input
        let isEmailValid = event.target.checkValidity();
    
        this.setState({
          input5: value,
          isEmailValid: isEmailValid,
        });
    }
      //  Send Email ToClient
    sendEmailToClient = async (calledFrom ,file) => {
        if(calledFrom === 'upload'){
            const files = file;
            console.log(file);
        }
    }
    handleSubmit() {
        this.sendEmailToClient('submit');
    }
    handleCredentialSubmit(){
        this.props.parentCallback(this.props.show, 'edit', this.state.input1);  
    }
    handleCallback(){
        this.props.parentCallback('sent');  
    }

  render(){ 
    return(
        <>
         <Modal  
         size="lg" 
         aria-labelledby="contained-modal-title-vcenter" 
         centered 
         className="email-model" 
         id="email-model"
         show={this.props.show} 
         onHide={()=>this.handleModal()}>  

         <Modal.Header closeButton>
         <div className="text-center col-11 font-f-sans white">
            {this.props.title}</div>
            </Modal.Header>  
            <Modal.Body className="ml-2 mr-2 mt-1 pb-1 pt-0">
            <form action='' onSubmit={this.handleSubmit}>
            <div className="row">
            {/* status 1 */}
            {this.props.input1 === 'text' ? 
            <div className='col-lg-6'>
            <label className="profile-label">{this.props.input1label}</label>
            <input name="input1" defaultValue={this.state.input1} type="text" placeholder='Please enter' onChange={this.handleChange}
            className='card-text form-control acc-form col-11' />
            </div>
            : this.props.input1 === 'textarea' ? 
            <>
            <label className="profile-label pt-1">{this.props.input1label}</label>
            <textarea defaultValue={this.state.input1} className="textarea form-control app-form pl-2" placeholder="Please Enter" 
            name="input1" onChange={this.handleChange} rows="4" cols="60"></textarea>
            <div className='d-flex flex-column'>
            <small className='profile-label mb-0 py-2'>Your account details are below:</small>
            <small className=''>URL: {this.props.for === 'partner' ? 
            'https://partner.aptitudemigration.com.au':
            'https://portal.aptitudemigration.com.au'}</small>
            <small>Email: {this.props.reciverEmail}</small>
            <small>Password: <b className='acc-label size-16'>Will be enclosed in Email </b></small>
            <br/>
            <small className='profile-label mb-0'>Thank you</small>
            <small className='text-muted bold'>{this.props.sendFrom}</small>
            <small className='mb-0' style={{textTransform: 'capitalize'}}>Aptitude Migration {this.props.loggedInUserBranch ? <>({this.props.loggedInUserBranch} 'Office')</> : ''}</small>
            <small className='theme-blue mb-0'>
            {this.props.loggedInUserBranch === 'lahore' ? '0092 344 000 2829':
            this.props.loggedInUserBranch === 'sydney' ? '0061 2 8212 4505 | 0432 710 739':
            this.props.loggedInUserBranch === 'samundri' ? '0092 334 4666 480':
            this.props.loggedInUserBranch === 'sahiwal' ? '0092 300 696 3438':
            this.props.loggedInUserBranch === 'faislabad' ? '0092 305 184 4480': ''}
            </small>
            {/* <small className='theme-blue '>info@aptitudemigration.com.au</small> */}
            </div>
            </>
            : ''}

            {/* provider email */}
            {this.props.type === 'provider' ? 
            <div className='col-lg-6'>
            <label className="profile-label">Provider Email</label>
            <input value={this.state.input5} name="input5" placeholder='Please enter' type="email" 
            onChange={this.handleEmailChange} className='card-text form-control acc-form col-11' />
            {!this.state.isEmailValid && (
            <div style={{color: 'red'}}>Please enter a valid email address.</div>
            )}
            </div>
            : ''}

             {/* status 2 */}
             {this.props.input2 === 'text' ? 
             <div className='col-lg-6'>
            <label className="profile-label">{this.props.input2label}</label>
            <input defaultValue={this.state.input2} name="input2" placeholder='Please enter' type="text" onChange={this.handleChange} className='card-text form-control acc-form col-11' />
            <p className='text-muted'> Separate keywords with a comma, space bar, or enter key</p>
            </div>
            : ''}
             {/* tags */}
            {this.props.tags && this.props.tags[0] && 
            <>
            <div className='col-lg-6 mb-lg-3 mt-1'>
            <label className="acc-label2 mb-0">Description Tags</label>
            <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example"
             onChange={(e) => this.setState({input3:e.target.value})}>
            <option  value=''>Please Select</option>
            {[].concat(this.props.tags).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
            <option key={value.id} className="light" value={value.description}>{value.name}</option> )}
            </select>
            </div>
            </>}
            {/* status 3 */}
            {this.props.input3 === 'textarea' ? <>
            <label className="profile-label mt-2">{this.props.input3label}</label>
            <textarea defaultValue={this.state.input3} className="textarea form-control app-form pl-2" placeholder="Please Enter" 
            name="input3" onChange={this.handleChange} rows="3" cols="60"></textarea>
             <div className='col-12'>
            {/* <p className='text-muted my-2'>fff</p> */}
            </div>
            <div className='d-flex flex-column'>
            {this.props.clientNote &&
            <>
            <div className='col-12 my-1 size-15'>
            <input className="form-check-input" name="showToPortal" type="checkbox" 
            onChange={(e) =>  this.setState(prevState => ({showToPortal: !prevState.showToPortal // Toggle the value
                }))} checked={this.state.showToPortal ? this.state.showToPortal : false}
            />
            <label className="form-check-label profile-label">Show this note to client portal!</label>
            </div>
            </>}
            <small className='profile-label mb-0'>Thank you</small>
            <small className='text-muted bold'>{this.props.sendFrom}</small>
            <small className='mb-0' style={{textTransform: 'capitalize'}}>Aptitude Migration ({this.props.loggedInUserBranch} Office)</small>
            <small className='theme-blue mb-0'> {
                this.props.loggedInUserBranch === 'lahore' ? '0092 344 000 2829':
                this.props.loggedInUserBranch === 'sydney' ? '0061 2 8212 4505 | 0432 710 739':
                this.props.loggedInUserBranch === 'samundri' ? '0092 334 4666 480':
                this.props.loggedInUserBranch === 'sahiwal' ? '0092 300 696 3438':
                this.props.loggedInUserBranch === 'faislabad' ? '0092 305 184 4480': ''}
            </small>
            {/* <small className='theme-blue '>info@aptitudemigration.com.au</small> */}
            </div>
            </>
            : ''}

            {/* status 4 */}
            {this.props.input4 === 'file' ? <>
            {/* <label className="profile-label mb-0">{this.props.input4label}</label> */}
            {((this.state.input1 || this.props.subject) && this.state.input3 ) &&
            <ImageUploadPreviewComponent 
            subject={this.state.input1 ? this.state.input1 : this.props.subject}
            bbc={this.state.input2}
            message={this.state.input3}
            clientid={this.props.clientid}
            socket={this.props.socket}
            user={this.props.user}
            page={this.props.page}
            appid={this.props.appid}
            appname={this.props.appname}
            thread={this.props.thread}
            replyTo={this.props.replyTo}
            for={this.props.for}
            type={this.props.type}
            provideremail={this.state.input5}
            showToPortal={(this.state.showToPortal === true || this.state.showToPortal === 'true') ? 1 : 0}
            />}

            </>
            : ''}
            </div>
            </form>
            </Modal.Body>  
            <Modal.Footer>  
            <Button className="btn btn-danger" onClick={() => this.handleModal()}> Cancel </Button>
            {this.props.credientials == true ?  
            <Button className="btn btn-success" onClick={() => this.handleCredentialSubmit()}> Send Credientals </Button>  
            :''}
            {/* <Button className="btn btn-success" onClick={() => this.handleSubmit()}> Send Email </Button> } */}
            </Modal.Footer>  
         </Modal>
        </>
    )
 }
}
export default SendEmailModel