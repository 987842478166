
import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import Paginations from '../GenericComponents/Pagination'
import { Link } from 'react-router-dom'
import { get_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import ExportOptions from '../GenericComponents/ExportOptions'
import Loader from '../Loader/Loader'
import swal from 'sweetalert'
import { Sorting } from '../GenericComponents/SortTables'
import MultipleSearch from '../GenericComponents/MultipleSearch'
import { FaEdit, FaEllipsisH, FaTrashAlt } from 'react-icons/fa'
import MultipleSearchs from '../GenericComponents/MultipleSearchs'
import { user_role } from '../../utilis/appStatus'

class ViewAccounts extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            loading:false,
            allUsers: [],
            open: false,
            clickedId: '',
            totalItem:'',
            perItem:'',
            currentPage: 1,
            lastPage:'',
            perPage: 10,
            search:'',
            order:'ASC',
            allPermissions: permissions,
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1},
                {title: 'Name' , dataKey: "name", key:2},
                {title: 'Role' , dataKey: "role", align: "text-center", key:3},
                {title: 'Branch', dataKey: "branchname", align: "text-center", key:4},
                {title: 'Status', dataKey: 'activestatus', align:"text-center",key:5},
                {title: 'IP Check', dataKey: 'ip_check', align:"text-center",key:6},
                {title: 'Mobile', dataKey: 'mobile', align:"text-center",key:7},
                {title: '', dataKey: '', key:8},
                {title: '', dataKey: '', key:9}
            ],
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUsers();
        document.addEventListener('click', this.handleClickOutside.bind(this), true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside.bind(this), true);
    };
    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            if (!this.ref.current.contains(event.target)) {
                this.setState({ open: false });
            }
        }
    };

    async getUsers() {
            this.setState({loading: true});
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url = 'getAllUsers';
            view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('Get All Users',res);
            this.setState({
                allUsers: res.data.data['data'],
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        });
    }
    //Delete a user
    async deleteUser(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                const data = delete_id;
                let url = 'deleteUser';
                get_ApiManager(data, url,(res) => {
                console.log('User Deleted',res);
                swal("User("+ name +")"+"deleted successfully", {icon: "success" });
                this.getUsers(); 
                this.setState({loading: false, currentPage:1});
            },(err) => {
                console.log(err);
            }); 
        }   
       });
    }
    // CallBack function to get changed status name and value and call api to save results
    handleCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage,currentPage: 1}, () => {  this.getUsers();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getUsers(); }); }
        } else {
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage,currentPage: 1}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); } 
        }
    }

    handleClick= (id) =>{
        this.setState({clickedId: id});
        if(this.state.open === true) this.setState({open:false});
        else this.setState({open:true})
    }
     // Table sorting
     sorting = (col) => {
      const sortResult = Sorting(this.state.allUsers, col, this.state.order)
      this.setState({order: sortResult[0], allUsers: sortResult[1]})
    }

     //Search Api
    handleSearchCallback = (input1, input2, input3) => {
            let Name, Role, Country;
            if (input1 === 'yes') {
                Name = this.state.name;
                Role = this.state.role;
                Country = this.state.country;
            }else{
                Name = input1;
                Role = input3;
                Country = input2;
                this.setState({search: 'yes', name: Name, role: Role, country: Country})

            }
            const data = {
                name:Name,
                role:Role,
                country:Country,
            }
            console.log(data);
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url = `search/accounts`;
            search_ApiManager(url, data, currentPage, perPage,(res) => {
                console.log('search result',res);
                this.setState({
                    allUsers: res.data.data['data'],
                    totalItem: res.data.data['total'],
                    perPage: res.data.data['per_page'],
                    lastPage: res.data.data['last_page'],
                    perItem: res.data.data['data'].length,
                });
            },(err) => {
                console.log(err);
            });
      
    }

  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    return(
        <>
        <Title title="View Accounts"
        btnCol="col-lg-8 mb-3 text-center pt-lg-1" 
        btn={true}
        btnText="Add Account" 
        btnClass="float-right btn btn-success"
        btnUrl="/accounts/add-account" />
        {this.state.allPermissions['accounts'] === 1 ?
        this.state.loading ? <Loader /> :
        <div className="row w-100 pl-3 pr-3 mb-5">
        {/* Search */}
        <MultipleSearchs
         input1={'Search Name'}
         input1_type={"input"}
         input2={'Search Country'}
         input2_type={"input"}
         input3={'Search Role'}
         input3_type={"dropdown"}
         input3_multiple={"no"}
         input3_filter_by={"title"}
         input3Data={user_role} 
         onSearchChange= {this.handleSearchCallback}
         />
        {/* Table */}
        <div className="table-responsive table-bordered mb-2">
        <table id="viewaccounts" className="table bg-white mb-0">
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {this.state.headers.map(head => (
         <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} key={head.key}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
        <tbody>
        {this.state.allUsers.map((value) => {
            return(
            <tr key={value.id}>
            <td className="text-center">{count++}</td>
            <td className="name-text">{value.name}</td>
            <td className="text-center">{value.role}</td>
            <td className="text-center">{value.branchname}</td>
            <td className={`text-center bold ${value.activestatus == 'Active' ? 'active' : 'text-delete'}`}> {value.activestatus} </td>
            <td className='text-center'>{value.ip_check}</td>
            <td className='text-center'>{value.mobile}</td>
            <td className="text-center">
            <Link to={`/accounts/edit-account/${value.id}/${'detail'}`} className="underline"> View Detail </Link></td>
            <td className="text-center">
            <span className="wrapper icon-ref">
            <span className="dot-icon">
            <a className="a-ref-icon" onClick ={() => this.handleClick(value.id)}>
            <FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
            </a>
            {this.state.open && this.state.clickedId === value.id &&
            <div className="actions-dots-dropdown">
            <Link to={`/accounts/edit-account/${value.id}/${'edit'}`} 
            className="btn btn-primary pl-3 pr-3 p-0 m-1 mt-2"> 
            <FaEdit size={12} color={'#fffcfc'} /> 
            </Link><br/>
            <a onClick ={() => this.deleteUser(value.id, value.name)} 
            className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
            <FaTrashAlt size={12} color={'#fffcfc'} /> </a>
            </div>} </span>
            </span>
            </td>
            </tr>)})}
        </tbody>
        </table>
        </div>
        <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handleCallback} 
        />
        {/* Exports */}
        {role === 2 &&
        <div className="d-flex mb-5">
        <ExportOptions
         pdffilename={'Accounts.pdf'} 
         csvfilename={'Accounts.csv'} 
         xlsxfilename={'Accounts'} 
         header="Accounts List"
         columns={this.state.headers}
         data={this.state.allUsers} />
        </div>}
        {/* End Exports */}
        </div>: 'You dont have permission to access this pages!'}
        </>
    )
  }
}
export default ViewAccounts