import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";

const SidebarLink = styled(Link)`
  display: flex;
  color: #dedede;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  padding-left: 12px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 14.3px;
  font-family: Roboto;
  transition: 200ms;
  border-radius: 50px 20px;
  ${'' /* font-weight:bold; */}
  &:hover {
    font-family: 'Fira Sans';
    background: rgb(58 197 58 / 72%);
    border-left: 9px solid #0962b7e6;
    font-weight:bold;
    transition: 200ms;
    z-index:12;
    font-size: 14.8px;
    color:cornsilk;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 7px;
`;

const DropdownLink = styled(Link)`
  height: 36px;
  width:100%;
  padding-left: 0.8rem;
  z-index:-2;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 13.5px;
  font-family: Roboto;
  &:hover {
    background: rgb(58 197 58 / 72%);
    font-weight:bold;
    color:white;
    cursor: pointer;
    font-family: 'Fira Sans', sans-serif;
  }
`;

const SubMenu = ({ item, sidebarClose}) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const role = useState(JSON.parse(localStorage.getItem('user_role')));
  const [permissions] = useState(JSON.parse(localStorage.getItem('permissions')));
  const user = useState(JSON.parse(localStorage.getItem('user_id')));

  return (
    <div key={item.title} className="pl-1">
     {(item.title === 'Manage Accounts' && permissions && permissions['accounts'] === 0) ? '' : 
     item.title === 'Manage Agents' && permissions && permissions['agents'] === 0 ? '' :
     item.title === 'Manage Providers' && permissions && permissions['providers'] === 0 ? '' : 
     item.title === 'Manage Finance' && permissions && permissions['finance'] === 0 ? '':
     item.title === 'Events' && permissions && permissions['show_events'] === 0 ? '':
     item.title === 'HR Management' && permissions && permissions['hr-management'] === 0 ? '' :
     (item.title === 'Manage Appointments' && permissions && permissions['viewappointments'] === 0 
     && permissions['appointments'] === 0) ? '' :
     item.title === 'Manage Branches' && permissions && permissions['manage_branches'] === 0 ? '':
    <>
      <SidebarLink key={item.subNav} to={item.subNav ? item.subNav : item.path} className="container w-100 h-100" onClick={item.subNav && showSubnav}>
        <div key={item.title} className="w-100 h-100" data-tip data-for={item.title} >
          {item.icon}
          {sidebarClose === true ?   
            <ReactTooltip key={item.title} id={item.title} backgroundColor="#6d6d6d" place="top" effect="solid">
            {item.title}
            </ReactTooltip>:
          <SidebarLabel > {item.title}</SidebarLabel>}
        </div>

        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
  
      {subnav &&
        <div className="sub-menu">
        {item.subNav.map((item, index) => {
          return (
            <div key={index}>
            {item.title === 'View Clients' && permissions && (permissions['clients'] === 0 || permissions['clients'] === '0') ? '' :
            item.title === 'View Leads' && permissions && (permissions['leads'] === 0 || permissions['leads'] === '0') ? '' : 
            item.title === 'Reports' && permissions && (permissions['reports'] === 0 || permissions['reports'] === '0') ? '' :
            item.title === 'Follow-ups' && permissions && (permissions['all_followups'] === 0 || permissions['all_followups'] === '0') ? '' :
            item.title === 'View Commissions' && permissions && (permissions['show_application_commission'] === 0 || permissions['show_application_commission'] === '0') ? '' :
            item.title === 'Attendance' && permissions && (permissions['show_attendance'] === 0 || permissions['show_attendance'] === '0') ? '' :
            item.title === 'Manage Leaves' && permissions && (permissions['manage_leaves'] === 0 || permissions['manage_leaves'] === '0') ? '' :
            item.title === 'Manage Roles' && permissions && (permissions['manage_roles'] === 0 || permissions['manage_roles'] === '0') ? '' :
            item.title === 'Alerts' && permissions && (permissions['can_create_alerts'] === 0 || permissions['can_create_alerts'] === '0') ? '' :
            item.title === 'View Daily Reports' && permissions && (permissions['hr-management'] === 0 || permissions['hr-management'] === '0') ? '' :
            <>
            <DropdownLink data-tip data-for={item.title} to={item.path} key={index}>
              {item.icon}
              {sidebarClose === true ?  
                <ReactTooltip key={index} id={item.title} backgroundColor="#6d6d6d" place="top" effect="solid">
                {item.title}
                </ReactTooltip>:
              <SidebarLabel>{item.title}</SidebarLabel>}
            </DropdownLink>
            </>}
            </div>
          );
        })}  
        </div>}
    </>
     }</div>
  );
};

export default SubMenu;