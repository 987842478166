
import React, { Component } from 'react';
import './inbox.css'
import {get_ApiManager, post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import { AiOutlineArrowLeft} from "react-icons/ai";
import { FiRefreshCcw } from "react-icons/fi";
import Moment from 'react-moment';
import DOMPurify from 'dompurify';
import { BsFillReplyFill } from "react-icons/bs";
import SendEmailModel from '../GenericComponents/SendEmailModel';
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Nav, Tab } from 'react-bootstrap';
import { faPlus, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import EmailDetailComponent from './EmailDetail';
import SendInboxEmail from './SendInboxEmail';

class HandleInbox extends Component {
    constructor(props) {
        super(props);
        var userDetail = JSON.parse(localStorage.getItem('user'));
        this.state = {
            allEmails:[],
            showEmail: false,
            isChecked: false,
            currentPage:1,
            perPage:10,
            lastPage:'',
            to:'',
            from:'',
            loading:false,
            totalItem:'',
            emailDetail: [],
            loggedInUser: userDetail,
            branchName:[],
            showEmailModel: false,
            header:[],
            selectedEmail:'',
            sendModel: false,
            allAgents:[],
        }
    }
    componentDidMount() {
        // this.getAllEmails();
        // this.getAgents();
        this.getPartnerChat();
    }
    getPartnerChat = () => {
        let url = `getPartnerChat`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('p-emails',res);
            this.setState({allEmails: res.data.data['data'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            currentPage: res.data.data['current_page'],
            totalItem: res.data.data['total'],
            from: res.data.data['from'],
            to: res.data.data['to']});
        },(err) => {
            console.log(err);
        });
    }
    handleDivClick = (email) => {
        this.setState({ selectedEmail: email });
    };
    handleBack = () => {
        this.setState({ selectedEmail: '' });
        this.getPartnerChat();
    };
    handleNotification = () => {
        const socket = this.props.socket;
        socket.emit("sendPartnerChatNotification");
    }
    handleEmailCallback = (type) => {
        if(type === 'edit'){
            this.handleNotification();
            this.getPartnerChat();
        }
        this.setState({sendModel: false})
     }
     handleMessage = () => {
        this.getPartners();
        this.setState({sendModel:true});
     }
     getPartners = () => {
        let url = `getagents/${2}`;
        view_ApiManager(url, 1, 100,(res) => {
        console.log('partners',res);
        this.setState({ allAgents: res.data.data.data});
        },(err) => {
            console.log(err);
        });
     }

    // getAllEmails = () => {
    //     let url = `getClientReply`;
    //     console.log(url);
    //     console.log(this.state.currentPage)
    //     view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
    //        console.log('all-emails',res);
    //        this.setState({allEmails: res.data.data['data'],
    //        perPage: res.data.data['per_page'],
    //        lastPage: res.data.data['last_page'],
    //        currentPage: res.data.data['current_page'],
    //        totalItem: res.data.data['total'],
    //        from: res.data.data['from'],
    //        to: res.data.data['to']});
    //     },(err) => {
    //         console.log(err);
    //     });
    // }
    // getAgents = () => {
    //     let url = 'getAgentsByOffice';
    //     get_ApiManager('', url,(res) => {
    //         console.log('agents',res);
    //         var name = res.data.data.filter(value => value.id == this.state.loggedInUser['main_branch']).map(v => ( v.agencyname))
    //         this.setState({branchName: name})
    //     },(err) => {
    //         console.log(err);
    //     })
    //  }

    // getEmail = (replyto, id, name) => {
    //     const data = {
    //         replyto: replyto,
    //         id: id,
    //         name:name
    //     }
    //     let url = `getEmailDetail`;
    //     console.log(url);
    //     post_ApiManager(data ,url,(res) => {
    //         console.log('email',res);
    //         this.setState({emailDetail: res.data.data['emails'], header: res.data.data['header']});
    //         this.handleNotification();
    //     },(err) => {
    //         console.log(err);
    //      });
    // }
    // //  Call Notification bell Counter
    // handleNotification = () => {
    //     const socket = this.props.socket;
    //     console.log('called notification')
    //     socket.emit("getInboxCount");
    // }

    // showEmailDetails = (replyto, id, name) => {
    //     this.setState({showEmail:true})
    //     this.getEmail(replyto, id, name) ;
    // }
    // handleEmailModelCallback(){
    //     this.setState({showEmailModel: false });
    // }  

    render(){
        if (this.state.selectedEmail) {
            return (
              <EmailDetailComponent email={this.state.selectedEmail} onBack={this.handleBack} socket={this.props.socket} />
            );
          }
        
        return(
            <> 
            <div className="bg-white rounded border m-2" style={{ height: '100vh' }}>
            <div className='row'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <Nav variant="underline" className='no-left-right-border px-2 py-1' >
            <Nav.Item><Nav.Link  eventKey="all">Partner Inbox</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="unread">Client Inbox</Nav.Link></Nav.Item>
            </Nav> 
            <Tab.Content>
                <Tab.Pane eventKey="all">
                <div className='row p-2' style={{background:'#f2f6fc'}}>
                {/* <div className='col-3'>
                <button className='btn btn-primary px-4' onClick={()  => this.handleMessage()}>
                <FontAwesomeIcon className="pr-2" icon={faPlus} />Send Message</button>
                </div> */}
                <div className='text-left'>
                <FontAwesomeIcon className="px-2 pointer" icon={faRefresh} color='#5f5f5f' onClick={() => this.getPartnerChat()} />
                </div>
                </div>
                {this.state.allEmails.map((value, index) => (
                <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${value.is_read === 0 ? 'unread':'read'}`} key={index} 
                onClick={() => this.handleDivClick(value.id)}>
                <div className='col-1'>{++index}</div>
                <div className='col-2'>{value.sender}
                {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
                {value.is_read === 0 && <> {moment(value.created_at).isSame(moment(), 'day') && (
                    <div className="rounded-text-div">New</div>)}</>}
                </div>
                <div className='col-8'>{value.subject}</div>
                <div className='col-1 size-14'> {moment(value.created_at).isSame(moment(), 'day')
                    ? moment(value.created_at).format('h:mm A') // Show time if today
                    : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
                }</div>
                </div>))}
                </Tab.Pane>
                <Tab.Pane eventKey="unread">
                    <h4>Option 2</h4>
                    <p>This is the content for Option 2.</p>
                </Tab.Pane>
            </Tab.Content>
            </Tab.Container>
            <div className="row p-2">
            <div className='col-lg-6'>
            <div className="text-left justify-content-left">
            <select value={this.state.perPage} onChange={(e) => this.setState({perPage: e.target.value}, () => this.getAllEmails())}  
            className="form-select page-form col-2 text-center">
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={this.state.totalItems}>All</option>
            </select>
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="text-right d-flex paginate j-content-right">{this.state.from ? this.state.from : 0}-{this.state.to ? this.state.to : 0} of {this.state.totalItem ? this.state.totalItem : 0}
            <button className="px-2 py-0 btn" onClick={() => { if (this.state.currentPage > 1) 
            this.setState({currentPage: this.state.currentPage-1}, () => this.getAllEmails() )}} style={{ cursor: this.state.currentPage === 1 ? 'default' : 'pointer' }}>
            <FaAngleLeft color={this.state.currentPage === 1 ? '#adadadc7' : ''} size={15} /></button>
            <button className="px-2 py-0 btn" style={{ cursor: this.state.currentPage === this.state.lastPage ? 'default' : 'pointer' }}
            onClick={() => {if (this.state.currentPage < this.state.lastPage) 
            this.setState({currentPage: this.state.currentPage+1}, () => this.getAllEmails() )}}>
            <FaAngleRight color={this.state.currentPage === this.state.lastPage ? '#adadadc7' : ''} size={15} /></button>
            </div>
            </div>
            </div>
            </div>
            </div>
         </>
          
        )
    }
}
export default HandleInbox