
import React, {useState} from 'react'
import { Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import swal from 'sweetalert';
import Bell from '../assets/BellIcon.mp3'
import { post_ApiManager } from './ApiManager/ApiManager';
import {useNavigate} from "react-router-dom"
import Loader from './Loader/Loader';
const audioClip = [{sound:Bell, label:'Bell'}]

const ImageUploadPreviewComponent = (props) => {
  const history = useNavigate();
  const [status, setStatus] = useState('');
  const [files, setFiles] = useState([]);
  const {Howl, Howler} = require('howler');

   const getUploadParams = (file) => {
      return { url: 'https://httpbin.org/post' }
  }

    const handleChangeStatus = ({ meta, file }, status) => {
      if (status === 'headers_received') {
        setStatus('uploaded!')
        setFiles(files => [...files,file]);
      } else if(status === 'removed'){
        setStatus('Removed')
      }
    }

   const handleSubmit = () => {
     
    const data = new FormData();
    
    if(props.page === 'offerPackage'){
      document.getElementById("offer-model").classList.add('none');
      if(files){
        console.log(files);
        for (let i = 0; i < files.length; i++) {
          data.append(`files[${i}]`, files[i])
        }
      } else data.append('files', 'null');
        data.append('offername', props.name);
        data.append('providerid', props.providerid);
        data.append('offerstartdate', props.startdate);
        data.append('offerenddate', props.enddate);
        let url = 'addPackageOffer';
        console.log('yy')
      post_ApiManager(data,url,(res) => {
          console.log('Offer Added',res);
          if(res.data.error){ 
            swal(" ", res.data.error, "error")
            document.getElementById("offer-model").classList.remove('none');
          } else {  
              swal("Success!", res.data.message, "success");
              history(0);
          }
      },(err) => {
          console.log(err);
      });
    }
    else{
        for (let i = 0; i < files.length; i++) {
          document.getElementById("email-model").classList.add('none');
        data.append(`files[${i}]`, files[i])
      }
      data.append('subject', props.subject);
      data.append('bcc', props.bbc);
      data.append('message', props.message);
      data.append('clientid', props.clientid);
      data.append('showToPortal', props.showToPortal);
      data.append('thread', props.thread);
      data.append('replyTo', props.replyTo);
      data.append('type', props.type);
      data.append('provideremail', props.provideremail)
      if(props.page === 'mig'){
        data.append('appid', props.appid);
        data.append('apptype', 1);
        data.append('appname', props.appname);
        data.append('notificationtype', 'mig');
        data.append('apptypefornotification', '1');
      } else if(props.page === 'edu'){
        data.append('appid', props.appid);
        data.append('apptype', 2);
        data.append('appname', props.appname);
        data.append('notificationtype', 'edu');
        data.append('apptypefornotification', '2');
      } else{
        data.append('appid', 0);
        data.append('apptype', 0);
        data.append('notificationtype', 'clients');
        data.append('apptypefornotification', '4');
      }
      console.log(data, ' email data');
      let url = 'sendEmailToClient';
        post_ApiManager(data,url,(res) => {
          console.log('email sent',res);
          if(res.data.error){ swal(" ", res.data.error, "error")}
          else { 
            handleNotification();
            swal("Success!", res.data.message, "success");
            history(0);
          }
        },(err) => {
          console.log(err);
        });
      }
    }
      //  Call Notification bell Counter
    const handleNotification = () => {
      const socket = props.socket;
      const user = props.user;
      socket.emit("sendNotification", {
        senderName: user,
      });
    }
    const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
      return (
        <div className='d-flex flex-column-reverse'>
          <div className=''>{previews}</div>
    ​
          <div {...dropzoneProps}>
            {files.length < maxFiles && input}
          </div>
    ​
          {files.length > 0 && submitButton}
        </div>
      )
    }

    return (
    <>
     <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={null}
      LayoutComponent={Layout}
      inputContent={props.content ? props.content :'Drag Files Here'}
      autoUpload={true}
      canCancel={true}
      canRemove={true}
      accept={props.action}
      maxFiles={props.maxFile}
      maxSizeBytes={10485760}
      styles={{
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        previewImage: {
               mazHeight: 20,
               maxWidth: 40
           },
           preview:{
               mazHeight:30,
               objectFit:"cover",
               padding: "5px !important",
           },
        dropzone: { 
               border: "1.5px dashed #898989",
               overflow:"hidden",
               textAlign: "center", },
        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
      }}
    />
    <small className='profile-label'>{status}</small>

    <Button className="btn btn-success" onClick={() => handleSubmit()}> {props.page === 'offerPackage' ?  'Save' : ' Send Email'} </Button>
      </>
    )
}
export default ImageUploadPreviewComponent
  