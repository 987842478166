
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import SeenStatus from '../Notification/SeenStatus';

const Inbox = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleIconClick = async () => {
        let path = `/handle-inbox`; 
        navigate(path);
    }
    
    return(
        <>
        <SeenStatus seenStatus={props.inboxSeen}/>
        <div className='text-center d-flex j-content-center icon-container'>
        <li className="wrapper">
            <a className="icon-button svg" onClick ={() => handleIconClick()}>
            {props.icon} </a>
        </li>
        <span className="hover-text">Inbox</span>
    </div>
        </>
    )
}
export default Inbox